import "./BrokerCardInfo.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import {calculateNodePoints} from "../../utils/brokersUtils"
import {brokerLevels} from "../../utils/collectionInfo"
import {NodeReferrals} from "../../utils/types"

import wolfImage from "../../Images/wolf.png"

function getBrokerLevel(totalRefs: number) {
    for (let level = 0; level < brokerLevels.length; level++) {
        const brokerLevel = brokerLevels[level]
        if (brokerLevel > totalRefs) return Math.max(level - 1, 0)
    }
}

const BrokerCardInfo: FC<any> = (props) => {
    const {loadingContainer, tree, searchedNftName, brokerName, className} = props

    const [brokerReferrals, setBrokerReferrals] = useState<NodeReferrals>()

    useEffect(() => {
        if (tree == undefined || brokerName == undefined) return
        setBrokerReferrals(calculateNodePoints(tree, brokerName))
    }, [brokerName, tree])

    return (
        !loadingContainer ? <div className={`broker-card-info d-flex flex-column ${className} ${searchedNftName.toLowerCase() === brokerName.toLowerCase() ? "searched" : "not-searched"}`}>
            {brokerReferrals !== undefined && <div className="broker-level-icon">{getBrokerLevel(brokerReferrals.totalReferrals)}</div>}
            <img src={wolfImage} />
            <h5 className="text-white text-center text-luxury mt-4 mb-2">{brokerName}</h5>
            {brokerReferrals !== undefined && <div className="points-info-container d-flex justify-content-between text-white">
                <div className="d-flex">
                    <span className="mx-2">{brokerReferrals.directReferrals}</span>
                    <span className="mx-2">{brokerReferrals.extraReferrals}</span>
                </div>
                <span className="mx-2">{brokerReferrals.totalReferrals}</span>
            </div>}
        </div>
        : <div className={`broker-card-info d-flex flex-column ${className}`}>
            <div className="loading-square" style={{width: "100%", height: 0, paddingBottom: "100%"}}></div>
            <div className="loading-square mx-auto mt-3 mb-4" style={{width: "150px"}}></div>
            <div className="d-flex justify-content-between text-white">
                <div className="d-flex">
                    <div className="loading-square" style={{width: "20px", height: "20px"}}></div>
                    <div className="loading-square ms-2" style={{width: "20px", height: "20px"}}></div>
                </div>
                <div className="loading-square ms-auto me-0" style={{width: "20px", height: "20px"}}></div>
            </div>
        </div>
    )
}

export default BrokerCardInfo