import axios from "axios";

import logo from "../../../Images/Icons/logo.png"

import {CollectionMarketplaceInfo} from "../../types"
import {delay} from "../../utils"
import {API_URL} from "../api"

const collectionMarketplaceInfo: CollectionMarketplaceInfo = {
    name: "Wolf of Aptos",
    imageUrl: logo,
    description: "Wolf of Aptos is the biggest cult of investors, traders and degens on the aptos chain, inspired by your favorite Wolf of Wall Street mindset for success.",
    floorPrice: "345 APT",
    totalVolume: "500,000 APT",
    dailyVolume: "75,000 APT",
}

async function getCollectionMarketplaceInfo(): Promise<CollectionMarketplaceInfo> {
    // await delay(5000)
    return collectionMarketplaceInfo
}






export default {
    getCollectionMarketplaceInfo
}