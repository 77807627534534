import "./Management.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faDiscord, faTwitter} from "@fortawesome/free-brands-svg-icons"

import { useWallet } from '@manahippo/aptos-wallet-adapter';
import queryString from 'query-string';

import Spinner from 'react-bootstrap/Spinner';

import Navbar from "../../Components/Navbar"
import UnderConstruction from "../../Components/UnderConstruction"

import api from "../../utils/api/api"
import { delay, animateLoadingSquares } from "../../utils/utils";
import { CollectionMarketplaceInfo } from "../../utils/types";

const Management: FC<any> = (props) => {

    // const wallet = useWallet()
    const [twitterLoggedIn, setTwitterLoggedIn] = useState(false)

    const twitterLogout = async () => {
        const {oauth_token, oauth_verifier}: any = queryString.parse(window.location.search);  

        const success = await api.whitelistInfo.twitterLogout(oauth_token);
        setTwitterLoggedIn(!success);
        // if (success) wallet.disconnect()
    }
    async function finalizeTwitterLogin() {
        const {oauth_token, oauth_verifier}: any = queryString.parse(window.location.search);  
            
        let success = false;
        if (oauth_token && oauth_verifier) {
            success = await api.whitelistInfo.finalizeTwitterLogin(oauth_token, oauth_verifier)
        }

        setTwitterLoggedIn(success);
    }
      
    useEffect(() => {
        finalizeTwitterLogin()
    }, []);

    return (
        <div id="management" className="d-flex flex-column">
            <Navbar />
            <div className="d-flex flex-column align-items-center mt-5">
                <button className="btn-default twitter-login-btn mb-3 py-2" style={{marginTop: "6rem", width: "fit-content"}} onClick={() => api.whitelistInfo.twitterLogin()}><h5 className="mb-0"><FontAwesomeIcon icon={faTwitter} className="me-2" />Login Twitter</h5></button>
            </div>
        </div>

    )
}

export default Management