import "./Navbar.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faDiscord, faTwitter} from "@fortawesome/free-brands-svg-icons"
import {faBars} from "@fortawesome/free-solid-svg-icons"

import Offcanvas from 'react-bootstrap/Offcanvas';

import logo from "../Images/Icons/logo.png"

import {MOBILE_MAX_WIDTH} from "../utils/constants"

const navigationLimitWidth = 1175;

const Navbar: FC<any> = (props) => {
    const {className} = props

    const [showOverlay, setShowOverlay] = useState(false)

    return (
        <div id="navbar" className={`d-flex justify-content-between ${className}`}>

            {window.innerWidth > MOBILE_MAX_WIDTH ? <a href="/" className="text-no-decoration d-flex align-items-center bg-first ms-5 px-3 pb-2">
                <img src={logo} className="logo-img" />
                <h4 className="text-luxury text-white text-bold mb-0 ms-2">Wolves Capital</h4>
            </a> : 
            <a href="/" className="text-no-decoration d-flex align-items-center bg-first ms-3 p-2 mt-2" style={{width: "70px", height: "70px"}}>
                    <img src={logo} className="img-fluid" />
            </a>}

            {window.innerWidth >= navigationLimitWidth ? <div className="d-flex justify-content-center align-items-start text-white me-5">
                <a href="/whitelist" className="text-no-decoration text-white d-flex bg-first mx-2 px-3" style={{width: "auto", height: "60px"}}><h4 className="text-luxury my-auto">Whitelist</h4></a>
                <a href="/build" className="text-no-decoration text-white d-flex bg-first mx-2 px-3" style={{width: "auto", height: "60px"}}><h4 className="text-luxury my-auto">Build</h4></a>
                <a href="/brokers-grove" className="text-no-decoration text-white d-flex bg-first mx-2 px-3" style={{width: "auto", height: "60px"}}><h4 className="text-luxury my-auto">Brokers Grove</h4></a>
                <a href="/marketplace" className="text-no-decoration text-white d-flex bg-first mx-2 px-3" style={{width: "auto", height: "60px"}}><h4 className="text-luxury my-auto">Marketplace</h4></a>
                <button disabled className="btn-simple d-flex bg-first mx-2 p-auto" style={{width: "60px", height: "60px", color: "grey"}}><FontAwesomeIcon icon={faDiscord} size="2x" className="m-auto" /></button>
                <a href="https://twitter.com/WolfofAptos" target="_blank" rel="noopener noreferrer" className="text-no-decoration text-white hover-transparent d-flex bg-first mx-2 p-auto" style={{width: "60px", height: "60px"}}><FontAwesomeIcon icon={faTwitter} size="2x" className="m-auto" /></a>
            </div> :
            <button className="btn-simple d-flex bg-first mx-2 p-auto mt-2" onClick={() => setShowOverlay(true)} style={{width: "70px", height: "70px"}}><FontAwesomeIcon icon={faBars} size="2x" className="m-auto" /></button>
            }

            {/* {window.innerWidth > MOBILE_MAX_WIDTH ? <><a href="/" className="text-no-decoration d-flex align-items-center bg-first ms-5 px-3 pb-2">
                <img src={logo} className="logo-img" />
                <h4 className="text-luxury text-white text-bold mb-0 ms-2">Wolves Capital</h4>
            </a>
            <div className="d-flex justify-content-center align-items-start text-white me-5">
                <a href="/mint" className="text-no-decoration text-white d-flex bg-first mx-2 px-3" style={{width: "auto", height: "60px"}}><h4 className="text-luxury my-auto">Mint</h4></a>
                <a href="/brokers-grove" className="text-no-decoration text-white d-flex bg-first mx-2 px-3" style={{width: "auto", height: "60px"}}><h4 className="text-luxury my-auto">Brokers Grove</h4></a>
                <a href="/marketplace" className="text-no-decoration text-white d-flex bg-first mx-2 px-3" style={{width: "auto", height: "60px"}}><h4 className="text-luxury my-auto">Marketplace</h4></a>
                <button disabled className="btn-simple d-flex bg-first mx-2 p-auto" style={{width: "60px", height: "60px", color: "grey"}}><FontAwesomeIcon icon={faDiscord} size="2x" className="m-auto" /></button>
                <a href="https://twitter.com/WolfofAptos" target="_blank" rel="noopener noreferrer" className="text-no-decoration text-white hover-transparent d-flex bg-first mx-2 p-auto" style={{width: "60px", height: "60px"}}><FontAwesomeIcon icon={faTwitter} size="2x" className="m-auto" /></a>
            </div></> : 
            <>
                <a href="/" className="text-no-decoration d-flex align-items-center bg-first ms-3 p-2 mt-2" style={{width: "70px", height: "70px"}}>
                    <img src={logo} className="img-fluid" />
                </a>
                <button className="btn-simple d-flex bg-first mx-2 p-auto mt-2" onClick={() => setShowOverlay(true)} style={{width: "70px", height: "70px"}}><FontAwesomeIcon icon={faBars} size="2x" className="m-auto" /></button>
            </>} */}

            <Offcanvas id="navbar-overlay" show={showOverlay} onHide={() => setShowOverlay(false)}>
                <Offcanvas.Header closeButton>
                <Offcanvas.Title className="d-flex align-items-center w-100">
                    <a href="/" className="text-no-decoration d-flex align-items-center">
                        <img src={logo} className="logo-img" />
                        <h4 className="text-luxury text-white text-bold mb-0 ms-2">Wolves Capital</h4>
                    </a>
                </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="d-flex flex-column justify-content-between">
                    <div className="d-flex flex-column">
                        <a href="/whitelist" className="text-no-decoration text-white d-flex bg-first mx-2 px-3" style={{width: "auto", height: "60px"}}><h4 className="text-luxury my-auto">Whitelist</h4></a>
                        <a href="/build" className="text-no-decoration text-white d-flex bg-first mx-2 px-3" style={{width: "auto", height: "60px"}}><h4 className="text-luxury my-auto">Build</h4></a>
                        <a href="/brokers-grove" className="text-no-decoration text-white d-flex bg-first mx-2 px-3" style={{width: "auto", height: "60px"}}><h4 className="text-luxury my-auto">Brokers Grove</h4></a>
                        <a href="/marketplace" className="text-no-decoration text-white d-flex bg-first mx-2 px-3" style={{width: "auto", height: "60px"}}><h4 className="text-luxury my-auto">Marketplace</h4></a>
                    </div>
                    <div className="d-flex justify-content-around w-100">
                        <button disabled className="btn-simple d-flex bg-first mx-2 p-auto" style={{width: "60px", height: "60px", color: "grey"}}><FontAwesomeIcon icon={faDiscord} size="2x" className="m-auto" /></button>
                        <a href="https://twitter.com/WolfofAptos" target="_blank" rel="noopener noreferrer" className="text-no-decoration text-white hover-transparent d-flex bg-first mx-2 p-auto" style={{width: "60px", height: "60px"}}><FontAwesomeIcon icon={faTwitter} size="2x" className="m-auto" /></a>
                    </div>
                {/* <button disabled className="btn-simple d-flex bg-first mx-2 p-auto" style={{width: "60px", height: "60px", color: "grey"}}><FontAwesomeIcon icon={faDiscord} size="2x" className="m-auto" /></button> */}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default Navbar;
