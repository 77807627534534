import "./MintContainer.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import { useWallet, BaseWalletAdapter } from '@manahippo/aptos-wallet-adapter';
import queryString from 'query-string';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faDiscord, faTwitter} from "@fortawesome/free-brands-svg-icons"
import {faArrowRight, faXmark, faCheck, faCopy} from "@fortawesome/free-solid-svg-icons"

import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

import PopoverContainer from '@idui/react-popover'
import {PopoverTriggerType} from '@idui/react-popover'

import ReactPlayer from 'react-player/youtube'


import Navbar from "../../Components/Navbar"
import UnderConstruction from "../../Components/UnderConstruction"
import ConnectWalletButton from "../../Components/Aptos/ConnectWalletButton"

import MintButton from "./MintButton"

import aptosCoin from "../../Images/Icons/aptosLogo.png"
import preMintVideo from "../../Videos/preMint.mp4"
import preMintPreview from "../../Images/premintPreview.png"

import musicIcon from "../../Images/Icons/musicIcon.png";
import musicPlayIcon from "../../Images/Icons/musicPlayIcon.png";
import musicIconMute from "../../Images/Icons/musicIconMute.png";

import { delay, capitalize, animateLoadingSquares, formatStringForUrl } from "../../utils/utils";
import { WhitelistInfo, MintedNftInfo } from "../../utils/types";
import api from "../../utils/api/api"
import { toast } from "react-toastify";

const mintPrice = 10;

interface MintInfo {
    referral: string;
    isReferralValid: boolean;
    numToMint: number;
    minting: boolean;

    success: boolean;
    mintedNfts: MintedNftInfo[];
}

// interface WlTwitterHandlesFetch {
//     fetching: boolean;
//     wlHandles: string[]|null;
//     fetch: Function;
// }
interface WalletWlInfoFetch {
    fetching: boolean;
    whitelistInfo: WhitelistInfo|null;
    fetch: Function;
}

const defaultValidMintReferrals = [
    "Danny Perez"
]

const musicLink = "https://www.youtube.com/watch?v=7-W92xs0iMY"
const referralUseTweet = "Use my referral code for @WolfofAptos upcoming mint:\n\n"

const pageUnderConstruction = false

const MintContainer: FC<any> = (props) => {
    const {musicInfo, setMusicInfo} = props
    
    const wallet = useWallet()

    const [twitterLoggedIn, setTwitterLoggedIn] = useState(false)
    const [savingWlInfo, setSavingWlInfo] = useState(false)
    // const [wlTwitterHandlesFetch, setWlTwitterHandlesFetch] = useState<WlTwitterHandlesFetch>({fetching: false, wlHandles: null, fetch: fetchWhitelistedTwitterHandles})

    const [connectedWalletWlInfo, setConnectedWalletWlInfo] = useState<WalletWlInfoFetch>({fetching: false, whitelistInfo: null, fetch: fetchWalletWLInfo})
    const [referralCopied, setReferralCopied] = useState(false)

    const [validMintReferrals, setValidMintReferrals] = useState<string[]>(defaultValidMintReferrals)

    const [mintInfo, setMintInfo] = useState<MintInfo>({referral: "", isReferralValid: false, numToMint: 1, minting: false, success: false, mintedNfts: []})

    async function copyWalletReferral() {
        const referral = connectedWalletWlInfo.whitelistInfo?.mintReferral
        if (referral === undefined) return
        setReferralCopied(true)
        navigator.clipboard.writeText(referral)
        await delay(750)
        setReferralCopied(false)
    }

    useEffect(() => {
        setMintInfo({...mintInfo, isReferralValid: validMintReferrals.includes(capitalize(mintInfo.referral))})
    }, [mintInfo.referral])

    // const connectWallet = async () => {
    //     if ("martian" in window) {
    //       console.log("connecting wallet")
    //       const connectionResponse = await window.martian.connect();
    //       const isConnected = await window.martian.isConnected()
    //       const sender = connectionResponse.address
    //       setConnectWalletInfo({sender, isConnected})
    //       console.log("wallet connected");
          
    //       return;
    //     }
    //     window.open("https://www.martianwallet.xyz/", "_blank");
    //   };

    useEffect(() => {
        if (!wallet.autoConnect && wallet.wallet?.adapter) {
            wallet.connect();
        }
      }, [wallet.autoConnect, wallet.wallet, wallet.connect]);

    useEffect(() => {
        if (!wallet.connected) return
        fetchWalletWLInfo()
    }, [wallet.connected])
    async function fetchWalletWLInfo() {
        const connectWalletAddress = wallet.account?.address?.toString()
        if (connectWalletAddress === undefined) return

        setConnectedWalletWlInfo({...connectedWalletWlInfo, fetching: true})
        const fetchPromise = api.whitelistInfo.fetchWalletWlInfo(connectWalletAddress)
        animateLoadingSquares("mint-referral-text", fetchPromise)
        const whitelistInfo = await fetchPromise;
        setConnectedWalletWlInfo({...connectedWalletWlInfo, fetching: false, whitelistInfo: whitelistInfo})
    }

      
    const twitterLogout = async () => {
        const {oauth_token, oauth_verifier}: any = queryString.parse(window.location.search);  

        const success = await api.whitelistInfo.twitterLogout(oauth_token);
        setTwitterLoggedIn(!success);
        if (success) wallet.disconnect()
    }
    async function finalizeTwitterLogin() {
        const {oauth_token, oauth_verifier}: any = queryString.parse(window.location.search);  
            
        let success = false;
        if (oauth_token && oauth_verifier) {
            success = await api.whitelistInfo.finalizeTwitterLogin(oauth_token, oauth_verifier)
        }

        setTwitterLoggedIn(success);
    }
      
    useEffect(() => {
        finalizeTwitterLogin()
    }, []);

    // useEffect(() => {
    //     fetchWhitelistedTwitterHandles()
    // }, [twitterLoggedIn])

    // async function fetchWhitelistedTwitterHandles() {
    //     setWlTwitterHandlesFetch({...wlTwitterHandlesFetch, fetching: true});
    //     const wlHandles = await api.whitelistInfo.fetchWhitelistedHandles();
    //     setWlTwitterHandlesFetch({...wlTwitterHandlesFetch, fetching: false, wlHandles});
    // }

    async function saveWlInfo() {
        if (wallet.account?.address?.toString() === undefined) return;

        const {oauth_token, oauth_verifier}: any = queryString.parse(window.location.search);  

        setSavingWlInfo(true)
        const [success, error_message] = await api.whitelistInfo.saveWhitelistInfo(oauth_token, wallet.account?.address?.toString())
        setSavingWlInfo(false)
        if (success) {
            toast.success("Saved WL info");
        } else {
            toast.error(error_message);
        }
    }
    


    return (
        <div id="mint-container" className="d-flex flex-column">
            <Navbar className={pageUnderConstruction ? null : "transparent"} />
            {pageUnderConstruction ? <UnderConstruction /> : <>
                <ReactPlayer id="music-player" url={musicLink} playing={musicInfo.isPlaying} loop={true} volume={musicInfo.volume} muted={musicInfo.isMuted} />
                <div id="music-controller" onMouseEnter={() => setMusicInfo({...musicInfo, controlsSelected: true})} onMouseLeave={() => setMusicInfo({...musicInfo, controlsSelected: false})}>
                    {musicInfo.isPlaying ? <button className="btn-simple" onClick={() => setMusicInfo({...musicInfo, isMuted: !musicInfo.isMuted})}><img src={musicInfo.isMuted ? musicIconMute : musicIcon} /></button> :
                    <button className="btn-simple" onClick={() => setMusicInfo({...musicInfo, isPlaying: true})}><img src={musicPlayIcon} className="p-2" /></button>}
                </div>

                <div id="video-container" className="photobanner">
                    {/* <video autoPlay loop muted={true} className="d-block m-auto">
                        <source src={preMintVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> */}
                    <img src={preMintPreview} style={{width: "100%", height: "100%", objectFit: "cover"}} />
                </div>

                <div id="mint-info" className="d-flex flex-column my-auto">
                    {mintInfo.minting && <div id="minting-hider"><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></div>}
                    <h2 className="text-white text-luxury text-center mb-5">Wolves Capital</h2>
                    {/* {wallet.connected ? 
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-3">
                                <input type="text" placeholder="Referral Code" id="mint-referral-input" className="input-simple ps-2 w-100" value={mintInfo.referral} onChange={(e) => setMintInfo({...mintInfo, referral: e.target.value})} />
                                {mintInfo.referral.length === 0 ? null : <div style={{width: "20px"}}>{mintInfo.isReferralValid ? <FontAwesomeIcon icon={faCheck} className="text-white ms-2" /> : <FontAwesomeIcon icon={faXmark} className="text-white ms-2" />}</div>}
                            </div>

                            <div className="d-flex justify-content-around align-items-center mt-2 mb-2">
                                <select className="form-select dark-select hover-pointer" style={{width: "fit-content"}} value={mintInfo.numToMint} onChange={(e) => setMintInfo({...mintInfo, numToMint: Number(e.target.value)})}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                                <FontAwesomeIcon icon={faArrowRight} className="text-white" />
                                <div className="d-flex align-items-center">
                                    <h4 className="text-white mb-0 me-2">{mintInfo.numToMint * mintPrice}</h4>
                                    <img src={aptosCoin} style={{width: "30px", height: "30px"}} />
                                </div>
                            </div>
                            
                            <MintButton mintInfo={mintInfo} setMintInfo={setMintInfo} />
                            <div id="mint-referral-text" className="d-flex justify-content-center align-items-center text-white mt-1 mb-5" style={{fontSize: ".8rem"}}>Your WL referral: {connectedWalletWlInfo.fetching ? <div className="loading-square d-inline" style={{width: "80px", height: "15px", background: "white", margin: "0 10px"}}></div> : connectedWalletWlInfo.whitelistInfo?.mintReferral} 
                                {connectedWalletWlInfo.whitelistInfo?.mintReferral !== undefined && connectedWalletWlInfo.whitelistInfo?.mintReferral.length > 0 && <PopoverContainer content={referralCopied ? "Copied" : "Copy"} trigger={"hover" as PopoverTriggerType } animation={{animate: {opacity: 1, scale: 1}, exit: {opacity: 0, scale: 0.9, transition: {duration: 0.1}}, initial: {opacity: 0, scale: 0.9}}} closeOnEnter fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck(){}} onFocus={function noRefCheck(){}} withArrow={false} ><button onClick={copyWalletReferral} className="btn-simple text-white ms-2"><FontAwesomeIcon icon={faCopy} /></button></PopoverContainer>}
                                {connectedWalletWlInfo.whitelistInfo?.mintReferral !== undefined && connectedWalletWlInfo.whitelistInfo?.mintReferral.length > 0 && <PopoverContainer content="Tweet" trigger={"hover" as PopoverTriggerType } animation={{animate: {opacity: 1, scale: 1}, exit: {opacity: 0, scale: 0.9, transition: {duration: 0.1}}, initial: {opacity: 0, scale: 0.9}}} closeOnEnter fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck(){}} onFocus={function noRefCheck(){}} withArrow={false} ><a className="twitter-share-button text-white ms-2" target="_blank" rel="noopener noreferrer" href={`https://twitter.com/intent/tweet?text=${formatStringForUrl(referralUseTweet + connectedWalletWlInfo.whitelistInfo?.mintReferral)}&url=https://twitter.com/GrahamStephan/status/1576601663477059585`}><FontAwesomeIcon icon={faTwitter} /></a></PopoverContainer>}
                            </div>
                            <ConnectWalletButton className="mt-5 mb-4" />
                        </div> : <ConnectWalletButton connectButton style={{marginBottom: "6rem"}} disabled={false} />} */}

                    <div className="d-flex flex-column">
                        {savingWlInfo ? <Spinner animation="border" role="status" className="mx-auto mb-5"><span className="visually-hidden">Loading...</span></Spinner>
                        : <> {wallet.connected && twitterLoggedIn && 
                            <div className="d-flex flex-column">
                                <h3 className="text-luxury text-white">Wl wallet</h3>
                                <h6 className="text-white w-100" style={{wordBreak: "break-all"}}>{wallet.account?.address?.toString()}</h6>
                                <button className="btn-default-reversed text-luxury text-bold px-2 ms-auto" onClick={saveWlInfo} style={{width: "fit-content"}}>Save</button>
                            </div>}
                        <button className="btn-default twitter-login-btn mb-3" style={{marginTop: wallet.connected ? "6rem" : "1rem"}} onClick={() => twitterLoggedIn ? twitterLogout() : api.whitelistInfo.twitterLogin()}><FontAwesomeIcon icon={faTwitter} className="me-2" />{twitterLoggedIn ? "Logout Twitter" : "Login Twitter"}</button>
                        <ConnectWalletButton connectButton={!wallet.connected} style={wallet.connected ? undefined : {marginBottom: "6rem"}} disabled={!twitterLoggedIn} /></>}
                    </div>
                    
                    <Modal id="mint-results-modal" show={mintInfo.success} onHide={() => setMintInfo({...mintInfo, success: false, mintedNfts: []})} centered size="lg">
                        <Modal.Body className="d-flex flex-column align-items-center pt-5 pb-3">
                            <h1 className="text-luxury mb-0">Welcome to Wolves Capital</h1>
                            <div className="d-flex justify-content-center my-5">
                                {mintInfo.mintedNfts.map(mintedNft => <div key={mintedNft.name} className="minted-nft-card d-flex flex-column mx-3">
                                    <img src={mintedNft.imageUri === null ? "" : mintedNft.imageUri} />
                                    <h5 className="text-white text-center text-luxury mt-2">{mintedNft.name}</h5>
                                </div>)}
                            </div>
                            <h6 className="mb-0" style={{color: "grey"}}>Location: Aptos Street</h6>
                        </Modal.Body>
                    </Modal>
                </div></>}
        </div>
    )
}

export default MintContainer