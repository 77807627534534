import "./ConnectWalletButton.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import {useWallet} from "@manahippo/aptos-wallet-adapter"

import aptosLogo from "../../Images/Icons/aptosLogo.png"

import PopoverContainer, {PopoverTriggerType} from '@idui/react-popover'
import ConnectWalletModal from "./ConnectWalletModal"
import {shortenAddress} from "../../utils/utils"

const ConnectWalletButton: FC<any> = (props) => {
    const {connectButton, className, style, disabled} = props

    const wallet = useWallet()
    const [showModal, setShowModal] = useState(false)

    function handleButtonClick() {
        if (connectButton) {
            setShowModal(true)
            return
        }
        wallet.disconnect()
    }

    const button = <button disabled={disabled} className={`connect-wallet-btn btn-simple ${className} ${disabled ? "disabled" : ""}`} onClick={handleButtonClick} style={style}>
        <img src={aptosLogo} />
        <h4 className="mb-0">{connectButton ? "Connect" : "Disconnect"}</h4>
    </button>

    return (
        <>
        {connectButton ? button : wallet.account?.address?.toString() !== undefined ? <span className="mx-auto w-100"><PopoverContainer content={shortenAddress(wallet.account?.address?.toString(), 10)} trigger={"hover" as PopoverTriggerType } animation={{animate: {opacity: 1, scale: 1}, exit: {opacity: 0, scale: 0.9, transition: {duration: 0.1}}, initial: {opacity: 0, scale: 0.9}}} closeOnEnter fitMaxHeightToBounds fitMaxWidthToBounds lazy offset={[0, 0]} onChangeOpen={function noRefCheck(){}} onFocus={function noRefCheck(){}} withArrow={false} >{button}</PopoverContainer></span> : null}
        <ConnectWalletModal show={showModal} onConnect={() => setShowModal(false)} />
        </>
    )
}

export default ConnectWalletButton;
