import "./UnderConstruction.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import underConstructionImage from "../Images/underConstruction.png"

const UnderConstruction: FC<any> = (props) => {
    return (
        <div className="d-flex flex-column under-construction-container">
            <h3 className="text-luxury text-white text-center">Under Construction</h3>
            <img src={underConstructionImage} className="under-construction-icon" />
        </div>
    )
}

export default UnderConstruction;
