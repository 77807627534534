import { gsap } from "gsap";

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const capitalize = (string: string, separator = " ") => string.split(separator).map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

export function shortenAddress(address: string, borderLengths: number = 5): string {
    return address.slice(0, borderLengths) + "..." + address.slice(-borderLengths, address.length)
}

const stringToUrlEncoding = {
    " ": "%20",
    "#": "%23",
    "@": "%40",
    "\n": "%0A",
    ":": "%3A",
}
export const formatStringForUrl = (str: string) => {
    Object.entries(stringToUrlEncoding).map(encoding => str = str.replaceAll(encoding[0], encoding[1]))
    return str;
    //stringToUrlEncoding.map() //str.replaceAll(" ", "%20").replaceAll("#", "%23").replaceAll("@", "%40").replaceAll("\n", "%0A").replaceAll(":", "%3A")
}

export function getTimeDifference(current: number, next: number): {days: number, hours: number, minutes: number, seconds: number} {
    var delta = Math.abs(next - current);

    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    var seconds = Math.floor(delta % 60);

    return {days, hours, minutes, seconds};
}



export async function animateLoadingSquares(containerId: string, promise: Promise<any>) {
    const fadeOutDuration = 1.5;
    const fadeInDuration = 1.5;

    const fadeOutValue = 0.3;
    const fadeInValue = 0.85;

    let loading = true;
    promise.then(() => loading = false)

    while (loading) {
        gsap.fromTo(`#${containerId} .loading-square`, {opacity: fadeInValue}, {opacity: fadeOutValue, duration: fadeOutDuration})
        await delay(fadeOutDuration * 1000)
        gsap.fromTo(`#${containerId} .loading-square`, {opacity: fadeOutValue}, {opacity: fadeInValue, duration: fadeInDuration})
        await delay(fadeInDuration * 1000)
    }
}