import "./Marketplace.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faDiscord, faTwitter} from "@fortawesome/free-brands-svg-icons"
import {faBars, faArrowUpRightFromSquare, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons"

import Spinner from 'react-bootstrap/Spinner';

import Navbar from "../../Components/Navbar"
import UnderConstruction from "../../Components/UnderConstruction"

import api from "../../utils/api/api"
import { delay, animateLoadingSquares } from "../../utils/utils";
import { CollectionMarketplaceInfo } from "../../utils/types";

const pageUnderConstruction = true

enum MarketplaceView {
    LISTINGS,
    ACTIVITY,
    ALL_ASSETS,
    BULK_BUY,
    LISTING_DETAIL
}


interface CollectionMarketplaceInfoFetch {
    fetching: boolean;
    collectionMarketplaceInfo: CollectionMarketplaceInfo|null;
    fetch: Function;
}

const Marketplace: FC<any> = (props) => {

    const [currentMarketplaceView, setCurrentMarketplaceView] = useState<MarketplaceView>(MarketplaceView.LISTINGS)
    const [collectionMarketplaceInfoFetch, setCollectionMarketplaceInfoFetch] = useState<CollectionMarketplaceInfoFetch>({fetching: false, collectionMarketplaceInfo: null, fetch: fetchCollectionMarketplaceInfo})

    useEffect(() => {
        fetchCollectionMarketplaceInfo()
    }, [])

    async function fetchCollectionMarketplaceInfo() {
        setCollectionMarketplaceInfoFetch({...collectionMarketplaceInfoFetch, fetching: true})
        const collectionMarketplaceInfoPromise = api.marketplace.getCollectionMarketplaceInfo()
        animateLoadingSquares("collection-info-container", collectionMarketplaceInfoPromise)
        animateLoadingSquares("collection-market-stats", collectionMarketplaceInfoPromise)
        const collectionMarketplaceInfo = await collectionMarketplaceInfoPromise
        setCollectionMarketplaceInfoFetch({...collectionMarketplaceInfoFetch, fetching: false, collectionMarketplaceInfo})
    }

    return (
        <div id="marketplace" className="d-flex flex-column">
            <Navbar />
            {pageUnderConstruction ? <UnderConstruction /> : <>

                <div id="collection-info-container" className="row justify-content-center justify-content-lg-start px-3 mx-0 w-100">
                    <div className="d-flex col-8 col-md-4 col-lg-2 col-xxl-2">{!collectionMarketplaceInfoFetch.fetching ? <img src={collectionMarketplaceInfoFetch.collectionMarketplaceInfo?.imageUrl} id="collection-info-logo" className="m-auto" /> : <div className="loading-square mx-0" style={{width: "150px", height: "150px", borderRadius: "50%"}}></div>}</div>
                    <div id="collection-info-data" className="d-flex flex-column text-white col-12 col-md-6 col-lg-4 col-xxl-3 ms-3">
                        {!collectionMarketplaceInfoFetch.fetching ? <h2 className="text-luxury text-center text-md-start mt-4">{collectionMarketplaceInfoFetch.collectionMarketplaceInfo?.name}</h2> : <div className="loading-square mt-4 mb-2 mx-0" style={{width: "200px", height: "50px"}}></div>}
                        {!collectionMarketplaceInfoFetch.fetching ? <p className="text-center text-md-start">{collectionMarketplaceInfoFetch.collectionMarketplaceInfo?.description}</p> : <div className="loading-square mx-0" style={{width: "350px", height: "80px"}}></div>}
                    </div>
                </div>
                <div id="collection-market-stats" className="d-flex ps-0 ps-md-5">
                    {/* {collectionMarketplaceInfo.marketStats.map(stat => <div className="d-flex flex-column align-items-center market-stat-container mx-4">
                        <h6 className="text-luxury text-bold">{stat.name}</h6>
                        <p className="mb-0">{stat.value}</p>
                    </div>)} */}
                    <div className="d-flex flex-column align-items-center market-stat-container mx-2 mx-md-4">
                        <h6 className="text-luxury text-bold text-center">Floor Price</h6>
                        {!collectionMarketplaceInfoFetch.fetching ? <p className="text-center mb-0">{collectionMarketplaceInfoFetch.collectionMarketplaceInfo?.floorPrice}</p> : <div className="loading-square" style={{width: "100px"}}></div>}
                    </div>
                    <div className="d-flex flex-column align-items-center market-stat-container mx-2 mx-md-4">
                        <h6 className="text-luxury text-bold text-center">Total Volume</h6>
                        {!collectionMarketplaceInfoFetch.fetching ? <p className="text-center mb-0">{collectionMarketplaceInfoFetch.collectionMarketplaceInfo?.totalVolume}</p> : <div className="loading-square" style={{width: "100px"}}></div>}
                    </div>
                    <div className="d-flex flex-column align-items-center market-stat-container mx-2 mx-md-4">
                        <h6 className="text-luxury text-bold text-center">Daily Volume</h6>
                        {!collectionMarketplaceInfoFetch.fetching ? <p className="text-center mb-0">{collectionMarketplaceInfoFetch.collectionMarketplaceInfo?.dailyVolume}</p> : <div className="loading-square" style={{width: "100px"}}></div>}
                    </div>
                </div>

                <div id="marketplace-view-selection-container" className="d-flex justify-content-between py-3 px-2 px-md-5">
                    <div className="d-flex">
                        <div onClick={() => setCurrentMarketplaceView(MarketplaceView.LISTINGS)} className={`d-flex marketplace-view-selection-option mx-3 ${currentMarketplaceView === MarketplaceView.LISTINGS ? "selected" : ""}`}>
                            <h5 className="text-luxury text-bold mb-0">Listings</h5>
                        </div>
                        <div onClick={() => setCurrentMarketplaceView(MarketplaceView.ACTIVITY)} className={`d-flex marketplace-view-selection-option mx-3 ${currentMarketplaceView === MarketplaceView.ACTIVITY ? "selected" : ""}`}>
                            <h5 className="text-luxury text-bold mb-0">Activity</h5>
                        </div>
                        <div onClick={() => setCurrentMarketplaceView(MarketplaceView.BULK_BUY)} className={`d-flex marketplace-view-selection-option mx-3 ${currentMarketplaceView === MarketplaceView.BULK_BUY ? "selected" : ""}`}>
                            <h5 className="text-luxury text-bold mb-0">Bulk Buy</h5>
                        </div>
                    </div>
                </div>
                <div id="marketplace-view-container" className="d-flex my-5"></div>
            
            </>}
        </div>
    )
}

export default Marketplace