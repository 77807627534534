import "./MainHandler.css"
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

import Home from "./Pages/Home/Home"
import MintContainer from "./Pages/Mint/MintContainer"
import BrokersGrove from "./Pages/Brokers/BrokersGrove"
import Marketplace from "./Pages/Marketplace/Marketplace"
import BuildWolf from "./Pages/BuildWolf/BuildWolf"
import Management from "./Pages/Management/Management"

import {delay} from "./utils/utils"

interface MusicInfo {
    volume: number;
    isMuted: boolean;
    isPlaying: boolean;
    controlsSelected: boolean;
}

const MainHandler: FC<any> = (props) => {

    const location = useLocation()
    const [locationPaths, setLocationPaths] = useState<string[]>([])

    const [musicInfo, setMusicInfo] = useState<MusicInfo>({volume: 50, isMuted: false, isPlaying: false, controlsSelected: false})

    useEffect(() => {
        console.log(location.pathname)
        
        // location.pathname.replace(/https:\/\/.+\//, "")
        const paths = location.pathname.slice(1, location.pathname.length).split("/")
        console.log(paths)
        setLocationPaths(paths)

    }, [location])

    return(
        <div id="main-handler" className="d-flex flex-column">
            <Switch>
                <Redirect exact from='/' to='/home' />
                <Route path="/home" children={<Home musicInfo={musicInfo} setMusicInfo={setMusicInfo} />}></Route>
                <Route path="/whitelist" children={<MintContainer musicInfo={musicInfo} setMusicInfo={setMusicInfo} />}></Route>
                <Route path="/brokers-grove" children={<BrokersGrove searchedNft={locationPaths[1] === undefined ? "" : locationPaths[1]} />}></Route>
                <Route path="/marketplace" children={<Marketplace />}></Route>
                <Route path="/build" children={<BuildWolf />}></Route>
                <Route path="/management-119865168456" children={<Management />}></Route>
                
            </Switch>

            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <ReactPlayer url='https://www.youtube.com/watch?v=9Ri7TcukAJ8' playing={isMusicPlaying} />
            <h1>Is Playing: {isMusicPlaying.toString()}</h1> */}
        </div>
    )
}

export default MainHandler