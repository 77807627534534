import axios from "axios";

import {TreeNode} from "../../types"
import {delay} from "../../utils"
import {API_URL} from "../api"

async function fetchBrokerTree(
    brokerName: string
): Promise<TreeNode|null> {
    // const data = JSON.stringify({ paper });
    // const customConfig = {
    //     headers: {
    //     'Content-Type': 'application/json'
    //     }
    // };

    // const response = await axios.post(`${API_URL}/papers/postPaper`, data, customConfig);
    // const responseData = response.data;
    // if(!responseData.success) {
    //     console.error(responseData.error);
    //     // return responseData;
    // }
    
    // return responseData;

    const response = await axios.get(`${API_URL}/brokers/getBrokerTree?brokerName=${brokerName}`);
    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return null
    }

    // await delay(30000)
    return responseData.referralTree === null ? null : responseData.referralTree.tree;
}


export default {
    fetchBrokerTree
}