import "./Home.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faDiscord, faTwitter} from "@fortawesome/free-brands-svg-icons"
import {faBars} from "@fortawesome/free-solid-svg-icons"

import mainHeroBackground from "../../Images/MainBackground.png";
import wolfOfAptosStreetPoster from "../../Images/WolfOfAptosStreetPoster.png";
import homeLandingPageVideo from "../../Videos/homePage.mp4";
import vinylPlayer from "../../Images/Icons/vinylPlayer.png";
import musicIcon from "../../Images/Icons/musicIcon.png";
import musicIconMute from "../../Images/Icons/musicIconMute.png";
import logo from "../../Images/Icons/logo.png"

import ReactPlayer from 'react-player/youtube'
import RangeSlider from 'react-range-slider-input';

import Navbar from "../../Components/Navbar"


const MOBILE_MAX_WIDTH = 850

const Home: FC<any> = (props) => {
    const {musicInfo, setMusicInfo} = props


    const [hasEntered, setHasEntered] = useState(false)

    useEffect(() => {
        setMusicInfo({...musicInfo, isPlaying: hasEntered})
    }, [hasEntered])

    // function handleMusicVolumeChange(e: any) {
    //     setMusicVolume(e[0])

    //     const rangeSliderRange: any = document.querySelector("#music-sound-range .range-slider .range-slider__range")
    //     console.log(rangeSliderRange)
    //     if (rangeSliderRange === null) return
    //     setTimeout(() => rangeSliderRange.style.top = `${musicVolume}%`, 0)
    // }

    return(
        <div id="home" className="d-flex flex-column">
            {!hasEntered && <div id="enter-container" className="d-flex flex-column justify-content-center align-items-center hover-pointer" onClick={() => setHasEntered(true)}>
                <h1 className="text-luxury text-center text-bold my-auto">Click to Enter Aptos Street.</h1>
            </div>}
            <div id="main-hero-container" className="d-flex bg-first p-3">
                <div className="w-100 h-100 position-relative">
                    <div className="photobanner d-flex justify-content-center">
                        {/* <img className="d-block m-auto" src={window.innerWidth > MOBILE_MAX_WIDTH ? mainHeroBackground : wolfOfAptosStreetPoster} /> */}
                        {window.innerWidth > MOBILE_MAX_WIDTH ? <video autoPlay loop muted={true} className="d-block m-auto" style={{width: "100%", height: "100%", objectFit: "cover"}}>
                            <source src={homeLandingPageVideo} type="video/mp4" />
                            <img src={mainHeroBackground} title="Your browser does not support the <video> tag" />
                        </video> : <img className="d-block m-auto" src={wolfOfAptosStreetPoster} />}
                    </div>
                    <Navbar className="transparent" />
                </div>
            </div>

            <ReactPlayer id="music-player" url='https://www.youtube.com/watch?v=9Ri7TcukAJ8' playing={hasEntered} loop={true} volume={musicInfo.volume} muted={musicInfo.isMuted} />
            <div id="music-controller" onMouseEnter={() => setMusicInfo({...musicInfo, controlsSelected: true})} onMouseLeave={() => setMusicInfo({...musicInfo, controlsSelected: false})}>
                {/* {musicControlsSelect && <div id="music-sound-range">
                    <RangeSlider orientation="vertical" thumbsDisabled={[false, true]} min={0} max={100} value={[musicVolume, 100]} onInput={handleMusicVolumeChange} onRangeDragStart={(e: any) => console.log(e)} />
                    <input type="range" value={musicVolume} onChange={(e) => setMusicVolume(Number(e.target.value))} min={0} max={100} style={{backgroundSize: `${(musicVolume * 100) / 100}% 100%`}} />
                </div>} */}
                <button className="btn-simple" onClick={() => setMusicInfo({...musicInfo, isMuted: !musicInfo.isMuted})}><img src={musicInfo.isMuted ? musicIconMute : musicIcon} /></button>
            </div>
        </div>
    )
}

export default Home