import "./BuildWolf.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";


import Jimp from 'jimp/es';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTwitter} from "@fortawesome/free-brands-svg-icons"
import {faDice} from "@fortawesome/free-solid-svg-icons"

import Form from 'react-bootstrap/Form';

import Navbar from "../../Components/Navbar"
import UnderConstruction from "../../Components/UnderConstruction"


import NoneTrait from "../../Images/BuildTraits/None.png"

// Background trait images
import BeigeBackground from "../../Images/BuildTraits/Background/Beige.png"
import BlueBackground from "../../Images/BuildTraits/Background/Blue.png"
import GreenBackground from "../../Images/BuildTraits/Background/Green.png"
import RedBackground from "../../Images/BuildTraits/Background/Red.png"
import VioletBackground from "../../Images/BuildTraits/Background/Violet.png"

// Clothes trait images
import AccountingClothes from "../../Images/BuildTraits/Clothes/Accounting.png"
import BlackSuitClothes from "../../Images/BuildTraits/Clothes/Black Suit.png"
import RedDealerClothes from "../../Images/BuildTraits/Clothes/Red Dealer.png"
import KingMantleClothes from "../../Images/BuildTraits/Clothes/King Mantle.png"
import BlueRobeClothes from "../../Images/BuildTraits/Clothes/Blue Robe.png"
import WhiteTurtleneck from "../../Images/BuildTraits/Clothes/White Turtleneck.png"
import FurGolden from "../../Images/BuildTraits/Clothes/Fur Golden.png"

// Hat trait Images
import CrownHat from "../../Images/BuildTraits/Hat/Crown.png"

// Eyewear trait Images
import AviatorBlackEyewear from"../../Images/BuildTraits/Eyewear/Aviator Black.png"
import AviatorGoldEyewear from"../../Images/BuildTraits/Eyewear/Aviator Gold.png"
import MonocleEyewear from"../../Images/BuildTraits/Eyewear/Monocle.png"


const traits: any = {
    "Background": [
        {name: "Beige", image: BeigeBackground},
        {name: "Blue", image: BlueBackground},
        {name: "Green", image: GreenBackground},
        {name: "Red", image: RedBackground},
        {name: "Violet", image: VioletBackground},
    ],
    "Fur": [
        {name: "Black", directory: "Fur/Black/"},
        {name: "Brown", directory: "Fur/Brown/"},
        {name: "Coffee", directory: "Fur/Coffee/"},
    ],
    "Mouth": [
        {name: "Normal"},
        {name: "Cigar"},
        {name: "Hungry"},
        {name: "Laughing"},
    ],
    "Eyes": [
        {name: "Angry"},
        {name: "Bored"},
        {name: "Dollars"},
        {name: "Glow"},
        {name: "Normal"},
        {name: "Scar"},
    ],
    "Clothes": [
        {name: "Accounting", image: AccountingClothes},
        {name: "Black Suit", image: BlackSuitClothes},
        {name: "Red Dealer", image: RedDealerClothes},
        {name: "King Mantle", image: KingMantleClothes},
        {name: "Blue Robe", image: BlueRobeClothes},
        {name: "White Turtleneck", image: WhiteTurtleneck},
        {name: "Fur Golden", image: FurGolden},
        {name: "None", image: NoneTrait},
    ],
    "Hat": [
        {name: "Crown", image: CrownHat, hasEars: false},
        {name: "None", image: NoneTrait, hasEars: true},
    ],
    "Eyewear": [
        {name: "Aviator Black", image: AviatorBlackEyewear},
        {name: "Aviator Gold", image: AviatorGoldEyewear},
        {name: "Monocle", image: MonocleEyewear},
        {name: "None", image: NoneTrait},
    ]
}



const pageUnderConstruction = false

const BuildWolf: FC<any> = (props) => {

    const [selectedTraits, setSelectedTraits] = useState<any>()
    const [generatedImageData, setGeneratedImageData] = useState<any>()
    const [generatedImage, setGeneratedImage] = useState<any>()


    useEffect(() => {
        randomizeSelectedTraits()
    }, [])

    function randomizeSelectedTraits() {
        const newSelectedTraits: any = {} //{"Background": "", "Fur": "", "Mouth": "", "Eyes": "", "Clothes": "", "Hat": "", "Eyewear": ""}
        Object.keys(traits).map((traitCategory: string) => {
            const traitsOfCategory = traits[traitCategory]
            const trait = traitsOfCategory[Math.floor(Math.random() * traitsOfCategory.length)];
            newSelectedTraits[traitCategory] = trait;
        })
        setSelectedTraits(newSelectedTraits)
        console.log(newSelectedTraits)
    }

    // ["Background", "Fur", "Clothes", "Ears", "Eyes", "Eyewear", "Mouth", "Hat"]
    async function generateWolf() {
        if (selectedTraits === undefined) return;
        console.log("Reading Images")
        let backgroundImage = await Jimp.read(selectedTraits["Background"].image)
        let furImage = Jimp.read(require("./" + selectedTraits["Fur"].directory + "Fur.png"))
        let clothesImage = Jimp.read(selectedTraits["Clothes"].image)
        let earsImage = Jimp.read(selectedTraits["Hat"].hasEars ? require("./" + selectedTraits["Fur"].directory + "Ears.png") : NoneTrait)
        let eyesImage = Jimp.read(require("./" + selectedTraits["Fur"].directory + "Eyes/" + selectedTraits["Eyes"].name + ".png"))
        let eyewearImage = Jimp.read(selectedTraits["Eyewear"].image)
        let mouthImage = Jimp.read(require("./" + selectedTraits["Fur"].directory + "Mouth/" + selectedTraits["Mouth"].name + ".png"))
        let hatImage = Jimp.read(selectedTraits["Hat"].image)

        const images = await Promise.all([furImage, clothesImage, earsImage, eyesImage, eyewearImage, mouthImage, hatImage])
        console.log("Overlaying Images")
        for (const image of images) backgroundImage = backgroundImage.composite(image, 0, 0);
        console.log("Saving Image")
        setGeneratedImageData(backgroundImage)
        setGeneratedImage(await backgroundImage.getBase64Async(Jimp.MIME_JPEG))
        // setGeneratedImage(backgroundImage)
    }

    useEffect(() => {
        generateWolf()
    }, [selectedTraits])

    
    function changeTrait(traitCategory: any, e: any) {
        const newSelectedTraits = {...selectedTraits}
        newSelectedTraits[traitCategory] = traits[traitCategory].filter((trait: any) => trait.name === e.target.value)[0];
        console.log(newSelectedTraits)

        setSelectedTraits(newSelectedTraits)
    }

    async function saveGeneration() {
        const buffer = await generatedImageData.getBufferAsync(Jimp.MIME_PNG);
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "wolf.png"); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    return (
        <div id="build-wolf" className="d-flex flex-column">
            <Navbar />
            {pageUnderConstruction ? <UnderConstruction /> : 
            <>
                <div id="build-image-container" className="position-relative mx-auto mt-5">
                    <img src={generatedImage} id="build-image" />
                    <div id="build-image-border-top-left"></div>
                    <div id="build-image-border-top-right"></div>
                    <div id="build-image-border-bottom-left"></div>
                    <div id="build-image-border-bottom-right"></div>
                </div>
                <div className="d-flex justify-content-center">
                    <button onClick={saveGeneration} className="btn-simple text-bold mt-2">Save</button>
                </div>
                <button id="randomize-traits-dice" className="btn-simple mx-auto" onClick={randomizeSelectedTraits} style={{marginTop: "6rem"}}><div></div><FontAwesomeIcon icon={faDice} size="2x" /></button>
                <div id="build-traits-container" className="container-fluid row justify-content-center text-white mx-0" style={{marginTop: "6rem"}}>
                    {Object.keys(traits).map((traitCategory: string) => <div className="d-flex flex-column align-items-center col-10 col-sm-6 col-md-4 col-lg-3 col-xl-2 my-3">
                        <h3 className="text-luxury">{traitCategory}</h3>
                        <Form.Select className="input-simple" onChange={(e) => changeTrait(traitCategory, e)} value={selectedTraits === undefined ? null : selectedTraits[traitCategory].name}>
                            {
                                traits[traitCategory].map((traitInfo: any) => <option value={traitInfo.name}>{traitInfo.name}</option>)
                            }
                        </Form.Select>
                    </div>)}
                </div>
            </>}
        </div>
    )
}

export default BuildWolf;
