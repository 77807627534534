export const MOBILE_MAX_WIDTH = 850;


export const NODE_URL = "https://fullnode.devnet.aptoslabs.com/v1"

// CANDY MACHINE CONFIG
export const candyMachineConfig = {
    cmAddress: "0x6eb6f8b95c512da78d6f83c75bfee87a940e205362bb28e308ca69645584ec82",
    collectionDataAddress: "0x92495b69a8895db213c5f33512e578eca465941aa53aa24190502443ec04166b",
    collectionTokenDataHandle: "0x44e63efa1a03c3693911a4d3743284433baee61e6fb2fb856ca63242ccd34484",
    collectionName: "Wolves Capital",

    wlLaunchTimestamp: 1665266400,
    publicLaunchTimestamp: 1665566400,
    mintFee: "100000000"
}