import brokers from "./routes/brokers"
import leaderboards from "./routes/leaderboards"
import royaltyShare from "./routes/royaltyShare"
import whitelistInfo from "./routes/whitelistInfo"
import marketplace from "./routes/marketplace"

export const API_URL = "https://us-central1-wolves-capital.cloudfunctions.net/api/v1";

export default {
    brokers,
    leaderboards,
    royaltyShare,

    whitelistInfo,
    marketplace
}