import axios from "axios";

import {WhitelistInfo} from "../../types"
import {delay} from "../../utils"
import {API_URL} from "../api"


async function saveWhitelistInfo(
    oauthToken: string,
    wlWallet: string
): Promise<[boolean, string]> {
    const data = JSON.stringify({ oauthToken, wlWallet });
    const customConfig = {
        headers: {
        'Content-Type': 'application/json'
        }
    };

    try {
        const response = await axios.post(`${API_URL}/whitelistInfo/setWlInfo`, data, customConfig);
        const responseData = response.data;
        if(!responseData.success) {
            console.error(responseData.error);
            return [false, responseData.error];
        }
    } catch (error: any) {
        console.error(error);
        return [false, "An error occured while saving WL info"];
    }
    
    return [true, ""];
}

// async function fetchWhitelistedHandles(): Promise<string[]> {
//     let whitelistedHandles: string[] = [];
//     try {
//         const response = await axios.get(`${API_URL}/whitelistInfo/getWlHandles`)
//         const responseData = response.data;
//         whitelistedHandles = responseData.whitelistedHandles;
//         if(!responseData.success) {
//             console.error(responseData.error);
//             return [];
//         }
//     } catch (err) {
//         console.error(err);
//         return [];
//     }

//     return whitelistedHandles;
// }

async function fetchWalletWhitelistInfo(
    wallet: string
): Promise<WhitelistInfo|null> {
    let whitelistInfo: WhitelistInfo;
    try {
        const response = await axios.get(`${API_URL}/whitelistInfo/getWalletWhitelistInfo?wallet=${wallet}`)
        const responseData = response.data;
        whitelistInfo = responseData.walletWhitelistInfo;
        if(!responseData.success) {
            console.error(responseData.error);
            return null;
        }
    } catch (err) {
        console.error(err);
        return null;
    }

    return whitelistInfo;
}

async function fetchWalletWlInfo(
    wallet: string
): Promise<WhitelistInfo|null> {
    await delay(2000)
    return {
        userDiscord: "Donnie#3087",
        wallet: "0x00000000000000000000",
        mintReferral: "Donnie#3087",
    }
}


async function twitterLogin(): Promise<boolean> {
    try {
        //OAuth Step 1
        const response = await axios({
          url: `${API_URL}/whitelistInfo/twitter/oauth/request_token`, 
          method: 'POST'
        });
        
        const { oauth_token } = response.data;
        //Oauth Step 2
        window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauth_token}`;
    } catch (error) {
        console.error(error); 
        return false
    }
    return true;
}
async function finalizeTwitterLogin(
    oauthToken: string,
    oauthVerifier: string,
): Promise<boolean> {
    try {
        //Oauth Step 3
        await axios({
        url: `${API_URL}/whitelistInfo/twitter/oauth/access_token`,  
        method: 'POST',
        data: {oauthToken, oauthVerifier}
        });
    } catch (error) {
        console.error(error);
        return false;
    }

    return true;
}

async function twitterLogout(
    oauthToken: string
): Promise<boolean> {
    try {
        await axios({
          url: `${API_URL}/whitelistInfo/twitter/logout`, 
          method: 'POST',
          data: {oauthToken}
        });
    } catch (error) {
        console.error(error);
        return false;
    }
    return true;
}


export default {
    saveWhitelistInfo,
    // fetchWhitelistedHandles,
    fetchWalletWhitelistInfo,
    fetchWalletWlInfo,

    twitterLogin,
    finalizeTwitterLogin,
    twitterLogout,
}