import "./BrokersGrove.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { useHistory } from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faDiscord, faTwitter} from "@fortawesome/free-brands-svg-icons"
import {faCheck, faXmark, faArrowUpRightFromSquare, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons"


import Tree, {withStyles} from '../../react-vertical-tree'
import Spinner from 'react-bootstrap/Spinner';

import Navbar from "../../Components/Navbar"
import UnderConstruction from "../../Components/UnderConstruction"
import PayoutTimer from "../../Components/PayoutTimer"
import ConnectWalletButton from "../../Components/Aptos/ConnectWalletButton"

import logo from "../../Images/Icons/logo.png"
import wolfImg from "../../Images/wolf.png"
import underConstructionImage from "../../Images/underConstruction.png"

import {MOBILE_MAX_WIDTH} from "../../utils/constants"
import {TreeNode, Leaderboard, RoyaltyShareInfo, RoyaltyShareLevel} from "../../utils/types"

import BrokerCardInfo from "./BrokerCardInfo"
import { delay, animateLoadingSquares } from "../../utils/utils";
import { nftNames } from "../../utils/collectionInfo";
import api from "../../utils/api/api"

interface SearchedNftTreeFetch {
    fetching: boolean;
    searchedNftTree: TreeNode|null|undefined;
    fetch: Function;
}
interface LeaderboardFetch {
    fetching: boolean;
    leaderboard: Leaderboard|null;
    fetch: Function;
}
interface BrokersLevelsFetch {
    fetching: boolean;
    brokersLevels: {0: number, 1: number, 2: number, 3: number, 4: number}|null;
    fetch: Function;
}
interface RoyaltyShareInfoFetch {
    fetching: boolean;
    royaltyShareInfo: RoyaltyShareInfo|null;
    currentRoyaltyShareLevel: RoyaltyShareLevel|null;
    nextRoyaltyShareLevel: RoyaltyShareLevel|null;
    fetch: Function;
}

const pageUnderConstruction = true

const BrokersGrove: FC<any> = (props) => {
    const {searchedNft} = props

    const history = useHistory()
    const [searchedNftName, setSearchedNftName] = useState<string>("")
    // const [currentSearchNftName, setCurrentSearchNftName] = useState<string>("")

    const [searchedNftTreeFetch, setSearchedNftTreeFetch] = useState<SearchedNftTreeFetch>({fetching: false, searchedNftTree: undefined, fetch: fetchSearchedNftTree})
    const [topBrokersLeaderboardFetch, setTopBrokersLeaderboardFetch] = useState<LeaderboardFetch>({fetching: false, leaderboard: null, fetch: fetchTopBrokersLeaderboard})
    const [totalBrokersLevelFetch, setTotalBrokersLevelFetch] = useState<BrokersLevelsFetch>({fetching: false, brokersLevels: null, fetch: fetchTotalBrokersLevels})
    const [royaltyShareInfoFetch, setRoyaltyShareInfoFetch] = useState<RoyaltyShareInfoFetch>({fetching: false, royaltyShareInfo: null, currentRoyaltyShareLevel: null, nextRoyaltyShareLevel: null, fetch: fetchTotalBrokersLevels})
    
    const searchBrokerInputEl = useRef<any>(null)

    const treeContainerRef = useRef<any>(null)
    const treeRef = useRef<any>(null)

    useEffect(() => {
        fetchTopBrokersLeaderboard()
        fetchTotalBrokersLevels()
        fetchRoyaltyShareInfo()
    }, [])

    useEffect(() => {
        if (searchedNft === undefined || searchedNft.length === 0) return
        const name = searchedNft.split("-").map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
        setSearchedNftName(name)
    }, [searchedNft])

    useEffect(() => {
        if (searchedNftName === undefined || searchedNftName.length === 0) return
        // setCurrentSearchNftName(searchedNftName)
        searchBrokerInputEl.current.value = searchedNftName
        fetchSearchedNftTree()
    }, [searchedNftName])

    async function fetchSearchedNftTree() {
        setSearchedNftTreeFetch({...searchedNftTreeFetch, fetching: true})
        const fetchPromise = api.brokers.fetchBrokerTree(searchedNftName);
        animateLoadingSquares("brokers-tree", fetchPromise)
        const searchedNftTree = await fetchPromise
        setSearchedNftTreeFetch({...searchedNftTreeFetch, fetching: false, searchedNftTree: searchedNftTree})
    }

    async function fetchTopBrokersLeaderboard() {
        setTopBrokersLeaderboardFetch({...topBrokersLeaderboardFetch, fetching: true})
        const fetchPromise = api.leaderboards.fetchLeaderboard("top-brokers");
        animateLoadingSquares("brokers-leaderboard", fetchPromise)
        const leaderboard = await fetchPromise;
        setTopBrokersLeaderboardFetch({...topBrokersLeaderboardFetch, fetching: false, leaderboard})
    }
    async function fetchTotalBrokersLevels() {
        setTotalBrokersLevelFetch({...totalBrokersLevelFetch, fetching: true})
        const fetchPromise = api.leaderboards.fetchLeaderboard("total-brokers-levels");
        animateLoadingSquares("total-brokers-levels-container", fetchPromise)
        const leaderboard = await fetchPromise;
        const brokersLevels: any = {}
        leaderboard?.leaderboardEntries.map((entry: any) => brokersLevels[entry.level] = entry.amountBrokers)
        setTotalBrokersLevelFetch({...totalBrokersLevelFetch, fetching: false, brokersLevels})
    }
    async function fetchRoyaltyShareInfo() {
        setRoyaltyShareInfoFetch({...royaltyShareInfoFetch, fetching: true})
        const fetchPromise = api.royaltyShare.fetchRoyaltyShareInfo();
        animateLoadingSquares("next-payout-timer", fetchPromise)
        animateLoadingSquares("royalty-share-info-container", fetchPromise)
        const royaltyShareInfo = await fetchPromise;
        const currentRoyaltyShareLevel = royaltyShareInfo === null ? null : royaltyShareInfo.shareLevels.filter(shareLevel => shareLevel.level === royaltyShareInfo.currentShareLevel)[0]
        const nextRoyaltyShareLevel = royaltyShareInfo === null ? null : royaltyShareInfo.shareLevels.filter(shareLevel => shareLevel.level === royaltyShareInfo.currentShareLevel + 1)[0]
        setRoyaltyShareInfoFetch({...royaltyShareInfoFetch, fetching: false, royaltyShareInfo, currentRoyaltyShareLevel, nextRoyaltyShareLevel})
    }

    const styles = {
        lines: {
          color: '#ffffff',
          height: '90px',
        },
        node: {
          backgroundColor: '#fff',
        //   border: '1px solid #1890ff',
        },
        text: {
          color: '#ccc',
        }
    }

    useEffect(() => {
        handleTreeScale()
        console.log("Update tree scale")
    })

    async function handleTreeScale() {
        if (treeRef.current === null) return
        await delay(1000)
        const scale = (window.innerWidth - 100) / treeRef.current.clientWidth
        treeRef.current.style.transform = `scale(${scale})`;

        treeContainerRef.current.style.height = treeRef.current.clientHeight * scale + "px";
    }
    const TreeComponent = withStyles(styles)(Tree);


    function searchBroker() {
        const brokerName: any = searchBrokerInputEl?.current?.value.toLowerCase().replaceAll(" ", "-");
        history.push(`/brokers-grove/${brokerName}`)
    }


    const nextRoyaltyShareRequirementsPassTest = royaltyShareInfoFetch.nextRoyaltyShareLevel?.requirements === undefined || totalBrokersLevelFetch.brokersLevels === null ? [false, false, false, false] : [
        royaltyShareInfoFetch.nextRoyaltyShareLevel?.requirements.brokersLevelOne <= totalBrokersLevelFetch.brokersLevels[1],
        royaltyShareInfoFetch.nextRoyaltyShareLevel?.requirements.brokersLevelTwo <= totalBrokersLevelFetch.brokersLevels[2],
        royaltyShareInfoFetch.nextRoyaltyShareLevel?.requirements.brokersLevelThree <= totalBrokersLevelFetch.brokersLevels[3],
        royaltyShareInfoFetch.nextRoyaltyShareLevel?.requirements.brokersLevelFour <= totalBrokersLevelFetch.brokersLevels[4],
    ]

    return(
        <div id="brokers-grove" className="d-flex flex-column">
            <Navbar />
            {pageUnderConstruction ? <UnderConstruction /> : <>
                <h1 className="title text-luxury text-white text-center" style={{marginTop: "8rem"}}>Brokers Grove</h1>
                <div className="d-flex flex-column align-items-center mt-5">
                    <div className="position-relative w-50">
                        <form onSubmit={(e) => searchBroker()}><input ref={searchBrokerInputEl} type="text" placeholder="Search Broker NFT"  id="broker-search" className="input-simple ps-2 py-1 w-100" /></form> {/*value={currentSearchNftName} onChange={(e) => setCurrentSearchNftName(e.target.value)}*/}
                        <button onClick={searchBroker} className="btn-simple position-absolute text-white" style={{right: "20px", top: "0", bottom: "0"}}><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                    </div>
                    
                    <h5 className="my-3 text-white">Or</h5>
                    <ConnectWalletButton connectButton className="mt-3" style={{width: "fit-content"}} />
                </div>

                {searchedNft !== undefined && 
                    <div id="brokers-tree" ref={treeContainerRef} className="d-flex flex-column justify-content-center align-items-center">
                        {searchedNftTreeFetch.fetching ? <TreeComponent data={[{root: "1", children: [{root: "2", children: []}, {root: "3", children: []}, {root: "4", children: []}]}]} direction render={ (item: any) => <BrokerCardInfo loadingContainer={true} className="loading-card mx-auto my-3"  />}/> // <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>
                        : searchedNftTreeFetch.searchedNftTree === null ? <h6 className="text-white text-center mb-0"><strong>{searchedNftName}</strong> is not part of any referral tree.</h6> : searchedNftTreeFetch.searchedNftTree !== undefined ? <TreeComponent reference={treeRef} data={[searchedNftTreeFetch.searchedNftTree]} direction render={ (item: any) => <BrokerCardInfo tree={searchedNftTreeFetch.searchedNftTree} searchedNftName={searchedNftName} brokerName={item.root} className="mx-auto my-3"  />}/>
                        : ""}
                    </div>
                }


                <div className="d-flex flex-column align-items-center mx-2" style={{marginTop: "15rem"}}>
                    <h1 className="text-luxury text-white text-center mb-4">Next Brokers Payout In</h1>
                    <PayoutTimer royaltyShareInfoFetch={royaltyShareInfoFetch} />
                </div>

                <div style={{width: "90%", height: "1px", background: "white", margin: "5rem auto"}}></div>

                <div id="total-brokers-levels-container" className="d-flex flex-column align-items-center text-white px-5">
                    <h1 className="text-luxury text-center mb-4" style={{fontSize: "2em"}}>Broker Levels</h1>
                    <div className="d-flex flex-column flex-lg-row w-100">
                        <div className="d-flex flex-column align-items-center justify-content-center col-12 col-lg my-3 my-lg-0">
                            <h1 className="text-luxury">0</h1>
                            {totalBrokersLevelFetch.brokersLevels === null ? <div className="loading-square" style={{width: "90px"}}></div> : <h5>{totalBrokersLevelFetch.brokersLevels[0]} Brokers</h5>}
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center col-12 col-lg my-3 my-lg-0">
                            <h1 className="text-luxury">1</h1>
                            {totalBrokersLevelFetch.brokersLevels === null ? <div className="loading-square" style={{width: "90px"}}></div> : <h5>{totalBrokersLevelFetch.brokersLevels[1]} Brokers</h5>}
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center col-12 col-lg my-3 my-lg-0">
                            <h1 className="text-luxury">2</h1>
                            {totalBrokersLevelFetch.brokersLevels === null ? <div className="loading-square" style={{width: "90px"}}></div> : <h5>{totalBrokersLevelFetch.brokersLevels[2]} Brokers</h5>}
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center col-12 col-lg my-3 my-lg-0">
                            <h1 className="text-luxury">3</h1>
                            {totalBrokersLevelFetch.brokersLevels === null ? <div className="loading-square" style={{width: "90px"}}></div> : <h5>{totalBrokersLevelFetch.brokersLevels[3]} Brokers</h5>}
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center col-12 col-lg my-3 my-lg-0">
                            <h1 className="text-luxury">4</h1>
                            {totalBrokersLevelFetch.brokersLevels === null ? <div className="loading-square" style={{width: "90px"}}></div> : <h5>{totalBrokersLevelFetch.brokersLevels[4]} Brokers</h5>}
                        </div>
                    </div>
                </div>

                <div style={{width: "90%", height: "1px", background: "white", margin: "5rem auto"}}></div>

                <div id="royalty-share-info-container" className="d-flex flex-column align-items-center">
                    {/* <h3 className="text-luxury text-white">Total Brokers</h3> */}
                    <div id="current-royalty-share-container" className="d-flex flex-column align-items-center">
                        <h4 className="text-luxury text-white text-center mt-4 mb-4">Current Royalty Share</h4>
                        {royaltyShareInfoFetch.fetching ? <div className="loading-square mb-2" style={{width: "180px", height: "40px"}}></div> : <h2 className="text-luxury text-bold text-white mb-2">{royaltyShareInfoFetch.currentRoyaltyShareLevel?.projectShare}% Project</h2>}
                        <div style={{width: "80%", height: "1px", background: "white"}}></div>
                        {royaltyShareInfoFetch.fetching ? <div className="loading-square mb-2" style={{width: "180px", height: "40px"}}></div> : <h2 className="text-luxury text-bold text-white mt-2">{royaltyShareInfoFetch.currentRoyaltyShareLevel?.brokersShare}% Brokers</h2>}
                    </div>
                    <div style={{width: "1px", height: "200px", background: "white"}}></div>
                    <div id="next-royalty-share-container" className="d-flex flex-column flex-md-row justify-content-center align-items-center text-white">
                        <div className="d-flex flex-column mt-auto me-0 me-md-3 me-lg-5 order-1 order-md-0">
                            {royaltyShareInfoFetch.fetching ? <div className="loading-square mb-2" style={{width: "200px"}}></div> : <p className={`text-luxury ${nextRoyaltyShareRequirementsPassTest[0] ? "" : "text-third"}`}><strong>{royaltyShareInfoFetch.nextRoyaltyShareLevel?.requirements.brokersLevelOne}</strong> Level 1 Brokers <FontAwesomeIcon icon={nextRoyaltyShareRequirementsPassTest[0] ? faCheck : faXmark} className="ms-2" /></p>}
                            {royaltyShareInfoFetch.fetching ? <div className="loading-square mb-2" style={{width: "200px"}}></div> : <p className={`text-luxury ${nextRoyaltyShareRequirementsPassTest[1] ? "" : "text-third"}`}><strong>{royaltyShareInfoFetch.nextRoyaltyShareLevel?.requirements.brokersLevelTwo}</strong> Level 2 Brokers <FontAwesomeIcon icon={nextRoyaltyShareRequirementsPassTest[1] ? faCheck : faXmark} className="ms-2" /></p>}
                        </div>
                        <div className="d-flex flex-column align-items-center mx-5 mb-3">
                            <h6 className="text-luxury text-white text-center mt-4 mb-4">Next Royalty Share</h6>
                            {royaltyShareInfoFetch.fetching ? <div className="loading-square mb-2" style={{width: "100px"}}></div> : <h4 className="text-luxury text-bold text-white mb-2">{royaltyShareInfoFetch.nextRoyaltyShareLevel?.projectShare}% Project</h4>}
                            <div style={{width: "80%", height: "1px", background: "white"}}></div>
                            {royaltyShareInfoFetch.fetching ? <div className="loading-square mb-2" style={{width: "100px"}}></div> : <h4 className="text-luxury text-bold text-white mt-2">{royaltyShareInfoFetch.nextRoyaltyShareLevel?.brokersShare}% Brokers</h4>}
                        </div>
                        <div className="d-flex flex-column mt-auto ms-0 ms-md-3 ms-lg-5 order-last">
                            {royaltyShareInfoFetch.fetching ? <div className="loading-square mb-2" style={{width: "200px"}}></div> : <p className={`text-luxury ${nextRoyaltyShareRequirementsPassTest[2] ? "" : "text-third"}`}><strong>{royaltyShareInfoFetch.nextRoyaltyShareLevel?.requirements.brokersLevelThree}</strong> Level 3 Brokers <FontAwesomeIcon icon={nextRoyaltyShareRequirementsPassTest[2] ? faCheck : faXmark} className="ms-2" /></p>}
                            {royaltyShareInfoFetch.fetching ? <div className="loading-square mb-2" style={{width: "200px"}}></div> : <p className={`text-luxury ${nextRoyaltyShareRequirementsPassTest[3] ? "" : "text-third"}`}><strong>{royaltyShareInfoFetch.nextRoyaltyShareLevel?.requirements.brokersLevelFour}</strong> Level 4 Brokers <FontAwesomeIcon icon={nextRoyaltyShareRequirementsPassTest[3] ? faCheck : faXmark} className="ms-2" /></p>}
                        </div>
                    </div>
                    {/* <div id="total-brokers-levels-container" className="d-flex flex-column w-100 align-items-center">
                        {totalBrokersLevelFetch.leaderboard !== null && totalBrokersLevelFetch.leaderboard.leaderboardEntries.map((entry, index) => <div className={`d-flex total-brokers-levels-line ${index % 2 === 0 ? "line-left" : "line-right"}`}>
                            {index % 2 === 0 ? <>
                                <h1 className="text-luxury">{entry.level}</h1>
                                <h5>{entry.amountBrokers} brokers</h5></>
                            : <>
                                <h5>{entry.amountBrokers} brokers</h5>
                                <h1 className="text-luxury">{entry.level}</h1></>
                            }
                        </div>)}
                    </div> */}
                </div>



                <div className="d-flex flex-column" style={{marginTop: "15rem"}}>
                    <h3 className="text-luxury text-white text-center">Leaderboard</h3>
                    <div id="brokers-leaderboard" className="d-flex justify-content-center mt-4">
                        <table>
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>Broker</th>
                                    <th>Direct Referrals</th>
                                    <th>Extra Referrals</th>
                                    <th>Total Referrals</th>
                                    {!topBrokersLeaderboardFetch.fetching && <th></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {topBrokersLeaderboardFetch.fetching ? 
                                    Array(5).fill(0).map((_, index) => <tr key={index}>
                                        <td><div className="loading-square" style={{width: "50px"}}></div></td>
                                        <td className="text-start"><div className="d-flex align-items-center"><div className="loading-square" style={{width: "50px", height: "50px"}}></div><div className="loading-square" style={{width: "70px"}}></div></div></td>
                                        <td><div className="loading-square" style={{width: "50px"}}></div></td>
                                        <td><div className="loading-square" style={{width: "50px"}}></div></td>
                                        <td><div className="loading-square" style={{width: "50px"}}></div></td>
                                        {/* <td style={{textAlign: "start", width: "50px"}}><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></td> */}
                                    </tr>) :
                                    topBrokersLeaderboardFetch.leaderboard !== null && topBrokersLeaderboardFetch.leaderboard.leaderboardEntries.map((entry, index) => <tr key={entry.rank}>
                                        <td className="text-luxury text-bold">{index + 1}</td>
                                        <td className="text-luxury text-start"><img src={wolfImg} className="me-2" />{entry.broker.toLowerCase()}</td>
                                        <td className="text-luxury">{entry.directReferrals}</td>
                                        <td className="text-luxury">{entry.extraReferrals}</td>
                                        <td className="text-luxury">{entry.totalReferrals}</td>
                                        <td style={{textAlign: "start", width: "50px"}}><a href={`/brokers-grove/${entry.broker.toLowerCase().replace(" ", "-")}`} className="text-white hover-pointer"><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a></td>
                                    </tr>)
                                    }
                            </tbody>
                        </table>
                    </div>
                </div>

                
                
                </>}
        </div>
    )
}

export default BrokersGrove